import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import 'react-toastify/dist/ReactToastify.css';
import { IMaskInput } from '@mirco312312/react-imask';

import { validateEmail } from '../../utils/validation';

import hmLogo from '../../assets/hm-logo.svg';

import api from '../../services/emeserver/api';

import authToken from '../../services/emeserver/authToken';

import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'components/Select';

export default function Register(): JSX.Element {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [permission, setPermission] = useState({
    label: 'Corretor',
    value: 'corretor',
  });

  const [group, setGroup] = useState({
    label: 'Selecione',
    value: '',
  });

  const setGroupOutros = (e) => {
    setGroup({ label: e, value: e });
  };

  const [outros, setOutros] = useState(false);

  const getAllGroups = useCallback(async () => {
    let groupsResponse;
    if (selectedAgent.permission === 'admin-super') {
      groupsResponse = await api.get('/dashboard/agents/getGroups', {
        headers: {
          Authorization: authToken.token,
        },
      });
    } else {
      groupsResponse = await api.get(
        '/dashboard/agents/getImobGroup/' + selectedAgent.group_dashboard,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    }

    const groups = groupsResponse.data.response_data;

    return groups;
  }, []);

  useEffect(() => {
    async function setAllAgents() {
      const listGroups = [
        {
          label: 'HM Vendas',
          value: 'hmvendas',
        },
        {
          label: 'HM São Paulo',
          value: 'hmsaopaulo',
        },
      ]
      setGroupsOptions(listGroups);
    }

    async function fetch() {
      await setAllAgents();
    }
    fetch();
  }, [getAllGroups]);

  const history = useHistory();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (name.length <= 0) {
      return toast.error('O Nome precisa ser preenchido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (cpf.length <= 0) {
      return toast.error('O CPF precisa ser preenchido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (phone.length <= 0) {
      return toast.error('O Telefone precisa ser preenchido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (email.length <= 0) {
      return toast.error('O E-mail precisa ser preenchido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!validateEmail(email)) {
      return toast.error(
        'O E-mail fornecido não está em um formato válido. Ex: corretor@corretohm.com.br',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    }

    if (!group.value) {
      return toast.error('O grupo deve ser informado', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (password.length <= 0) {
      return toast.error('A Senha precisa ser preenchida', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const data = {
      full_name: name,
      cpf,
      email,
      phone_number: phone,
      password_hash: password,
      permission: permission.value,
      group_dashboard: group.value,
    };

    try {
      const response = await api.post('agents/create', data, {
        headers: {
          Authorization: authToken.token,
        },
      });

      if (response.status === 201) {
        if (response.data.status && response.data.status === 'existent_agent') {
          return toast.error(response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
  
        return history.push('/home');
      }
    } catch(error) {
      return toast.error(error.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <S.Container>
      <S.Card>
        <S.LogoContainer>
          <ReactSVG src={hmLogo} />
          <h1>
            Dashboard <span>Leads</span>
          </h1>
        </S.LogoContainer>

        <S.Form>
          <S.InputsLabel>Nome</S.InputsLabel>
          <S.InputsContainer>
            <S.InputContainer
              isEmpty={name === ''}
              style={{ marginBottom: '10px' }}
            >
              <input
                style={{ width: '100%' }}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </S.InputContainer>

            <S.InputsLabel>CPF</S.InputsLabel>
            <S.InputContainer
              isEmpty={cpf === ''}
              style={{ marginBottom: '10px' }}
            >
              <IMaskInput
                placeholder="000.000.000-00"
                mask="000.000.000-00"
                value={cpf}
                unmask
                onAccept={(value: string, _mask: string) => {
                  return setCpf(value);
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '7px',
                  padding: '5px',
                }}
              />
            </S.InputContainer>

            <S.InputsLabel>Telefone</S.InputsLabel>
            <S.InputContainer
              isEmpty={phone === ''}
              style={{ marginBottom: '10px' }}
            >
              <IMaskInput
                placeholder="(00) 00000-0000"
                mask="(00) 00000-0000"
                value={phone}
                unmask
                onAccept={(value: string, _mask: string) => {
                  return setPhone(value);
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '7px',
                  padding: '5px',
                }}
              />
            </S.InputContainer>

            <S.InputsLabel>E-mail</S.InputsLabel>
            <S.InputContainer
              isEmpty={email === ''}
              style={{ marginBottom: '10px' }}
            >
              <input
                style={{ width: '100%' }}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </S.InputContainer>

            <S.InputsLabel>Corretor</S.InputsLabel>

            <Select
              value={permission}
              onChange={(e) => {
                if (e?.label && e?.value) {
                  setPermission({
                    label: e?.label,
                    value: e?.value,
                  });
                }
              }}
              style={{
                width: '24vw',
                height: '5.5rem',
                marginBottom: '10px',
                border: 'none',
                backgroundColor: '#f3f3f3',
                fontSize: '1.2rem',
                color: '#f3f3f3',
              }}
              placeholder="Selecione um corretor"
              options={[
                {
                  label: 'Corretor',
                  value: 'corretor',
                },
                {
                  label: 'Administrador',
                  value: 'admin',
                },
              ]}
            />

            <S.InputsLabel>Grupo</S.InputsLabel>

            <Select
              value={group}
              onChange={(e) => {
                if (e?.label && e?.value) {
                  setGroup({
                    label: e?.label,
                    value: e?.value,
                  });
                }
                if (e.value === 'outro') {
                  setOutros(true);
                } else {
                  setOutros(false);
                }
              }}
              style={{
                width: '24vw',
                height: '5.5rem',
                marginBottom: '10px',
                border: 'none',
                backgroundColor: '#f3f3f3',
                fontSize: '1.2rem',
                color: '#f3f3f3',
              }}
              placeholder="Selecione o grupo de dashboard"
              options={groupsOptions}
            />
            <S.InputsLabel hidden={!outros}>
              Grupo (Somente para Imobiliárias SEM ESPAÇOS)
            </S.InputsLabel>
            <S.InputContainer
              hidden={!outros}
              isEmpty={!group}
              style={{ marginBottom: '10px' }}
            >
              <div className="input-group-prepend">
                <span
                  style={{ fontSize: 'medium' }}
                  className="input-group-text"
                  id="basic-addon3"
                >
                  eme_plantao_
                </span>
              </div>
              <input
                style={{ width: '100%' }}
                type="group"
                value={group.label}
                className="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
                onChange={(e) => setGroupOutros(e.target.value)}
                onBlur={(e) =>
                  setGroup({
                    label: `eme_plantao_${e.target.value}`,
                    value: `eme_plantao_${e.target.value}`,
                  })
                }
              />
            </S.InputContainer>
            <S.InputsLabel>Senha</S.InputsLabel>
            <S.InputContainer isEmpty={password === ''}>
              <input
                placeholder="Senha"
                value={password}
                type={showPassword ? 'initial' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword ? (
                <S.IconContainer>
                  <FiEye
                    onClick={(_e) => setShowPassword(!showPassword)}
                    size={20}
                  />
                </S.IconContainer>
              ) : (
                <S.IconContainer>
                  <FiEyeOff
                    onClick={(_e) => setShowPassword(!showPassword)}
                    size={20}
                  />
                </S.IconContainer>
              )}
            </S.InputContainer>
          </S.InputsContainer>

          <div style={{ marginTop: '4rem' }}>
            <S.ButtonContainer
              onClick={handleSubmit}
              style={{ marginRight: '10px' }}
            >
              <span>Cadastrar</span>
            </S.ButtonContainer>

            <S.ButtonContainer onClick={() => history.push('/home')}>
              <span>Cancelar</span>
            </S.ButtonContainer>
          </div>
        </S.Form>
      </S.Card>
      <ToastContainer />
    </S.Container>
  );
}
