import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Field } from 'formik';

import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';

import FormContext from 'context/FormContext';

import AlreadyRegistered from '../AlreadyRegistered';
import { IMaskInput } from '@mirco312312/react-imask';
import * as S from './styles';

interface StepTwoProps {
  alreadyRegistered: boolean;
  handleFetchRegisteredLead: Function;
  agentName: string;
}

const StepTwo: React.FC<StepTwoProps> = (props) => {
  const { fields, setFields } = useContext(FormContext);
  const [agents, setAgents] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [possueCPF, setPossueCPF] = useState('');
  const [dashboardAgent, setDashboardAgent] = useState('');
  const [origins] = useState([
    { value: 'facebook', label: 'Facebook' },
    { value: 'whatsapp-hm-vendas', label: 'WhatsApp HM Vendas' },
    { value: 'google', label: 'Google' },
    { value: 'vivareal', label: 'Vivareal' },
    { value: 'zap', label: 'Zap Imóveis' },
    { value: 'olx', label: 'OLX' },
    { value: 'organico', label: 'Orgânico' },
    { value: 'email', label: 'E-Mail' },
    { value: 'indique-amigos', label: 'Indique Amigos' },
    { value: 'linkedin', label: 'Linkedin' },
    { value: 'outros', label: 'Outros' },
  ]);

  const [conversion_channel] = useState([
    { value: 'facebook', label: 'Facebook' },
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'midia-off', label: 'Mídia Off' },
    { value: 'indicacao', label: 'Indicação' },
    { value: 'ativo-corretores', label: 'Ativo Corretores' },
    { value: 'form', label: 'Formulários(Site/Landing Pages)' },
  ]);

  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
  const permission = sessionStorage.getItem('permission');

  function changeString(e, property) {
    setFields({ ...fields, [property]: e.target.value });
  }

  function getPhoneNumber(e, property) {
    props.handleFetchRegisteredLead();
  }

  function getCpf(e, property) {
    props.handleFetchRegisteredLead();
  }

  function getDashboard(e, property) {
    props.handleFetchRegisteredLead();
  }

  const getAllAgents = useCallback(async () => {
    let agentsResponse;
    if (permission === 'admin') {
      agentsResponse = await api.get(
        '/agents?group=' + selectedAgent.group_dashboard,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    } else if (permission === 'admin-super') {
      agentsResponse = await api.get('/agents?group=' + fields.group_dashboard, {
        headers: {
          Authorization: authToken.token,
        },
      });
    } else {
      agentsResponse = await api.get('/agents/search/' + selectedAgent.id, {
        headers: {
          Authorization: authToken.token,
        },
      });
    }
    const { agents } = agentsResponse.data;


    return agents;
  }, [fields.group_dashboard]);

  const getEnterprises = useCallback(async () => {
    let enteprisesResponse;
    if (permission === "admin-super") {
      enteprisesResponse = await api.get(
        '/enterprises/list/searchgroup?group_dashboard=' +
          fields.group_dashboard,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    } else {
      enteprisesResponse = await api.get(
        '/enterprises/list/searchgroup?group_dashboard=' +
          selectedAgent.group_dashboard,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    }

    const { response_data } = enteprisesResponse.data;

    return response_data;
  }, [fields.group_dashboard]);

  useEffect(() => {
    async function setAllAgents() {
      const agents = await getAllAgents();

      const filterValidAgents = agents.filter(
        (agent) => agent.permission === 'corretor' && agent.activated,
      );

      setAgents(filterValidAgents);
    }

    async function setAllEnterprises() {
      const enteprises = await getEnterprises();

      const filterValidEnterprises = enteprises.filter(
        (enterprise) => enterprise.slug !== '',
      );

      setEnterprises(filterValidEnterprises);
    }
 
    async function fetch() {
      await setAllAgents();
      await setAllEnterprises();
    }

    fetch();
  }, [getAllAgents, getEnterprises]);

  useEffect(() => {
    async function setAllEnterprises() {
      const enteprises = await getEnterprisesGeral(dashboardAgent);

      setEnterprises(enteprises);
    }

    async function fetch() {
      await setAllEnterprises();
    }

    fetch();
  }, [dashboardAgent]);

  const getEnterprisesGeral = useCallback(async (dashboard: string) => {
    const enteprisesResponse = await api.get(
      '/enterprises/list/searchgroup?group_dashboard=' + dashboard,
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    const { response_data } = enteprisesResponse.data;

    return response_data;
  }, []);

  async function handleHaveCPF(e) {
    setPossueCPF(e)

    if(e === 'nao') setFields({ ...fields, ['cpf']: '' });
  }

  const callTypes = [
    {
      callType: 'Agendar visita',
      value: 'visit',
    },
    {
      callType: 'Financiamento',
      value: 'financing',
    },
  ];
  return (
    <S.Container>
      {props.alreadyRegistered ? (
        <AlreadyRegistered agent={props.agentName} />
      ) : (
        <>
        {permission === "admin-super" && (
          <select
            name="groupDashboard"
            value={fields.group_dashboard}
            required
            onChange={(e) => changeString(e, 'group_dashboard')}
            onBlur={(e) => getDashboard(e, 'group_dashboard')}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Dashboard
            </option>
            <option value="hmvendas">HM Vendas</option>
            <option value="hmsaopaulo">HM São Paulo</option>
          </select>
        )}
          
          <Field
            className="fieldStyle"
            required
            autoFocus
            placeholder="Nome"
            value={fields.full_name}
            onChange={(e) => changeString(e, 'full_name')}
            disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
          />

          <Field>
            {({ field }) => (
              <IMaskInput
                {...field}
                id="phone_number"
                className="fieldStyle"
                required
                mask="(00) 00000-0000"
                placeholder="Telefone"
                onBlur={(e) => getPhoneNumber(e, 'phone_number')}
                value={fields.phone_number}
                onAccept={(value) => changeString({ target: { value } }, 'phone_number')}
                disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
                />
            )}
          </Field>

          <Field
            className="fieldStyle"
            required
            placeholder="E-mail"
            value={fields.email}
            onChange={(e) => changeString(e, 'email')}
            disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
          />
          <select
            name="possueCPF"
            value={possueCPF}
            required
            onChange={(e) => handleHaveCPF(e.target.value)}
            className="fieldStyle"
            disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
          >
            <option value="" hidden disabled>
              Possui CPF?
            </option>
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </select>

          {possueCPF === 'sim' && (
            <Field>
              {({ field }) => (
                <IMaskInput
                  {...field}
                  id="cpf"
                  required={possueCPF === 'sim' ? true : false}
                  className="fieldStyle"
                  placeholder="CPF"
                  mask="000.000.000-00"
                  onBlur={(e) => getCpf(e, 'cpf')}
                  value={fields.cpf}
                  onAccept={(value) => changeString({ target: { value } }, 'cpf')}
                />
              )}
            </Field>
          )}
          <select
            name="origin"
            value={fields.origin}
            required
            onChange={(e) => changeString(e, 'origin')}
            className="fieldStyle"
            disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
          >
            <option value="" hidden disabled>
              Origem
            </option>
            {origins.map((origin) => (
              <option key={origin.value} value={origin.value}>
                {origin.label}
              </option>
            ))}
          </select>

          <select
            name="conversion_channel"
            value={fields.conversion_channel}
            required
            onChange={(e) => changeString(e, 'conversion_channel')}
            className="fieldStyle"
            disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
          >
            <option value="" hidden disabled>
              Canal de Conversão
            </option>
            {conversion_channel.map((conversion) => (
              <option key={conversion.value} value={conversion.value}>
                {conversion.label}
              </option>
            ))}
          </select>

          <select
            name="agent"
            value={fields.agent_id}
            required
            onChange={(e) => changeString(e, 'agent_id')}
            className="fieldStyle"
            disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
          >
            <option value="" hidden disabled>
              Corretor
            </option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.full_name} - {agent.group_dashboard}
              </option>
            ))}
          </select>

          <select
            name="enterprise"
            value={fields.enterprise_slug}
            required
            onChange={(e) => changeString(e, 'enterprise_slug')}
            className="fieldStyle"
            disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
          >
            <option value="" hidden disabled={(permission === 'admin-super' && !fields.agent_id) ? true : false}>
              Empreendimento
            </option>
            {enterprises.map((enterprise) => (
              <option key={enterprise.slug} value={enterprise.name}>
                {enterprise.title}
              </option>
            ))}
          </select>

          <select
            name="call_type"
            required
            value={fields.call_type}
            onChange={(e) => changeString(e, 'call_type')}
            className="fieldStyle"
            disabled={(permission === 'admin-super' && !fields.group_dashboard) ? true : false}
          >
            <option value="" hidden disabled>
              Tipo do atendimento
            </option>
            {callTypes.map((callType) => (
              <option key={callType.value} value={callType.value}>
                {callType.callType}
              </option>
            ))}
          </select>
        </>
      )}
    </S.Container>
  );
};

export default StepTwo;
