import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const api2 = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 15000,
});

const api = axios.create({
  //baseURL: 'https://backend.andrewisley.com.br/',
  baseURL: 'https://eme-server.devhagens.com.br',
  //baseURL: 'https://emeserver.maishm.com.br/',
});

export default api;
